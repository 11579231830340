exports.components = {
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-black-2021-tsx": () => import("./../../../src/pages/black2021.tsx" /* webpackChunkName: "component---src-pages-black-2021-tsx" */),
  "component---src-pages-black-js": () => import("./../../../src/pages/black.js" /* webpackChunkName: "component---src-pages-black-js" */),
  "component---src-pages-box-tsx": () => import("./../../../src/pages/box.tsx" /* webpackChunkName: "component---src-pages-box-tsx" */),
  "component---src-pages-cyber-js": () => import("./../../../src/pages/cyber.js" /* webpackChunkName: "component---src-pages-cyber-js" */),
  "component---src-pages-dieta-js": () => import("./../../../src/pages/dieta.js" /* webpackChunkName: "component---src-pages-dieta-js" */),
  "component---src-pages-exclusive-renewal-js": () => import("./../../../src/pages/exclusive-renewal.js" /* webpackChunkName: "component---src-pages-exclusive-renewal-js" */),
  "component---src-pages-firma-js": () => import("./../../../src/pages/firma.js" /* webpackChunkName: "component---src-pages-firma-js" */),
  "component---src-pages-hiit-js": () => import("./../../../src/pages/hiit.js" /* webpackChunkName: "component---src-pages-hiit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jeszcze-szybciej-js": () => import("./../../../src/pages/jeszcze-szybciej.js" /* webpackChunkName: "component---src-pages-jeszcze-szybciej-js" */),
  "component---src-pages-joanna-js": () => import("./../../../src/pages/joanna.js" /* webpackChunkName: "component---src-pages-joanna-js" */),
  "component---src-pages-lifetime-js": () => import("./../../../src/pages/lifetime.js" /* webpackChunkName: "component---src-pages-lifetime-js" */),
  "component---src-pages-mariola-js": () => import("./../../../src/pages/mariola.js" /* webpackChunkName: "component---src-pages-mariola-js" */),
  "component---src-pages-metabolizm-js": () => import("./../../../src/pages/metabolizm.js" /* webpackChunkName: "component---src-pages-metabolizm-js" */),
  "component---src-pages-nowosci-js": () => import("./../../../src/pages/nowosci.js" /* webpackChunkName: "component---src-pages-nowosci-js" */),
  "component---src-pages-nowyrok-tsx": () => import("./../../../src/pages/nowyrok.tsx" /* webpackChunkName: "component---src-pages-nowyrok-tsx" */),
  "component---src-pages-ostatnia-szansa-tsx": () => import("./../../../src/pages/ostatnia-szansa.tsx" /* webpackChunkName: "component---src-pages-ostatnia-szansa-tsx" */),
  "component---src-pages-ostatniaszansa-js": () => import("./../../../src/pages/ostatniaszansa.js" /* webpackChunkName: "component---src-pages-ostatniaszansa-js" */),
  "component---src-pages-piramida-js": () => import("./../../../src/pages/piramida.js" /* webpackChunkName: "component---src-pages-piramida-js" */),
  "component---src-pages-pyramid-js": () => import("./../../../src/pages/pyramid.js" /* webpackChunkName: "component---src-pages-pyramid-js" */),
  "component---src-pages-restart-js": () => import("./../../../src/pages/restart.js" /* webpackChunkName: "component---src-pages-restart-js" */),
  "component---src-pages-sprobuj-js": () => import("./../../../src/pages/sprobuj.js" /* webpackChunkName: "component---src-pages-sprobuj-js" */),
  "component---src-pages-szybciej-js": () => import("./../../../src/pages/szybciej.js" /* webpackChunkName: "component---src-pages-szybciej-js" */),
  "component---src-pages-szybko-js": () => import("./../../../src/pages/szybko.js" /* webpackChunkName: "component---src-pages-szybko-js" */),
  "component---src-pages-treningi-js": () => import("./../../../src/pages/treningi.js" /* webpackChunkName: "component---src-pages-treningi-js" */),
  "component---src-pages-twoja-szansa-tsx": () => import("./../../../src/pages/twoja-szansa.tsx" /* webpackChunkName: "component---src-pages-twoja-szansa-tsx" */),
  "component---src-pages-tylko-teraz-tsx": () => import("./../../../src/pages/tylko-teraz.tsx" /* webpackChunkName: "component---src-pages-tylko-teraz-tsx" */),
  "component---src-pages-update-needed-js": () => import("./../../../src/pages/update-needed.js" /* webpackChunkName: "component---src-pages-update-needed-js" */),
  "component---src-pages-yoga-js": () => import("./../../../src/pages/yoga.js" /* webpackChunkName: "component---src-pages-yoga-js" */)
}

